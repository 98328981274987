import { checkCurrentLanguage } from "shared/lib/checkCurrentLanguage";
import axiosApi from "./../../../../shared/api/axiosApi";

export const adminAPI = {
  getMarathonAdminById: async (id) => {
    return await axiosApi.get(`/marathons/admin/${id}`);
  },
  getUserByAdmin: async (userId) => {
    return await axiosApi.get(`/users/${userId}`);
  },
  getParticipantInfo: async (applicationId) => {
    return await axiosApi.get(`/applications/${applicationId}`);
  },
  checkParticipantsStartKitIssueType: async (applicationId) => {
    return await axiosApi.get(
      `/applications/starter-kit-check/${applicationId}`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  createMarathon: async (marathonInfoData) => {
    return await axiosApi.post("/marathons", marathonInfoData, {
      headers: {
        "content-type": "multipart/form-data",
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  putMarathonStatus: async (data) => {
    return await axiosApi.put("/marathons/status", data);
  },
  getUsers: async (query, signal) => {
    return await axiosApi.get(
      `/users?page=${query.page}&pageSize=${query?.pageSize
      }&filter=document%21%3Dnull%2Cdocument.disabilityPath%3Dnull${query.usersQuery.verify
      }${query.usersQuery.marathonName ?? ""}${query.usersQuery.documents}${query.usersQuery.searchName
      }`
    );
  },
  corporateIssue: (body, signal) =>
    axiosApi.put(`/applications/starterkit/corporate`, body, {
      headers: { "Content-type": "application/json" },
    }),
  getVerifiedUsers: (query, signal) => {
    return axiosApi.get(
      `/users?page=1&pageSize=1000&filter=document%21%3Dnull%2Cdocument.disabilityPath%3Dnull%2Cstatus%21%3Dnull%2Cstatus.currentStatus%3DConfirmed%2Cemail%21%3Dnull${query && `%2Cemail%3D${query}`
      }`,
      { signal }
    );
  },
  getaActivatedUsers: (query, signal) => {
    return axiosApi.get(
      `/users?page=1&pageSize=50&filter=document%21%3Dnull%2Cdocument.disabilityPath%3Dnull%2Cstatus%21%3Dnull%2Cstatus.currentStatus%3DConfirmed%2C%28email%21%3Dnull%2Cemail%3D%2A${query}%29`,
      { signal }
    );
  },
  postAsignPromocode: async (body, signal) => {
    return await axiosApi.post(
      `/vouchers/assignpromocodebyemail?email=${body.email}&promocodeId=${body.promocodeId}`,
      {},
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  unPostAsignPromocode: async (body, signal) => {
    return await axiosApi.post(
      `vouchers/promocode/unassign/${body.promocodeId}`
    );
  },
  getDisabledPeople: async (query) => {
    return await axiosApi.get(
      `/users?page=${query.page}&pageSize=${query.pageSize}&filter=document%21%3Dnull%2Cdocument.disabilityPath%21%3Dnull${query.disabledPeopleQuery.verify}${query.disabledPeopleQuery.marathonName ?? ""}${query.disabledPeopleQuery.documents}${query.disabledPeopleQuery.searchName}`
    );
  },
  getUserById: async (id) => {
    return await axiosApi.get(`/users/${id}`);
  },
  getStatusComments: async () => {
    return await axiosApi.get("statuses/comments");
  },
  postStatusComments: async (commentData) => {
    return axiosApi.post("statuses/comments", commentData);
  },
  deleteStatusComment: async (id) => {
    return axiosApi.delete(`statuses/${id}`);
  },
  putUserStatus: async (data) => {
    return await axiosApi.put(`statuses/${data.id}`, {
      newStatus: data.newStatus,
      commentsId: data.commentsId,
    });
  },
  updateMarathon: async (marathonData) => {
    return await axiosApi.put("/marathons", marathonData, {
      headers: { "content-type": "multipart/form-data" },
    });
  },
  // updatePublicOffer: async (marathonData) => {
  //   return await axiosApi.put("/agreements/update/1", marathonData, {
  //     headers: { "content-type": "multipart/form-data" },
  //   });
  // }
};
