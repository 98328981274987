import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
} from "@microsoft/signalr";

export const createSignalRConnection = (): HubConnection => {
  const connection = new HubConnectionBuilder()
    .withUrl("https://marathon-app.timelysoft.org/hubs/document-notification", {
      accessTokenFactory: () => localStorage.getItem("AccessToken") || "",
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => console.log("SignalR Connected"))
    .then(() => connection.invoke("GetNotifications"))
    .catch((error: any) => console.error("SignalR Connection Error:", error));

  connection.onclose(() => console.log("SignalR Disconnected"));

  return connection;
};
