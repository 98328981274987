import { useAppSelector } from "shared/lib/hooks/hooks";
import { NavLink } from "react-router-dom";
import { createPaths } from "shared/lib/createPaths";
import {
  ADMIN_PANEL_PATH,
  ALL_USERS_PATH,
  USERS_PATH,
  VOUCHERS_PATH,
  MARATHONS_PATH,
  ROLES,
  NOTIFICATIONS_PATH,
} from "pages/paths";
import Box from "@mui/material/Box";
import styles from "./AdminNavItems.module.scss";
import { Typography } from "@mui/material";

const AdminNavItems = () => {
  const userRole = useAppSelector((state) => state.users.userRole);

  const unreadNotificationsCount = useAppSelector(
    (state) => state.marathon.unreadNotificationsCount
  );

  const ownerNavItems = [
    {
      label: "Уведомления",
      path: createPaths(ADMIN_PANEL_PATH, NOTIFICATIONS_PATH, ALL_USERS_PATH),
    },
    {
      label: "Пользователи",
      path: createPaths(ADMIN_PANEL_PATH, USERS_PATH, ALL_USERS_PATH),
    },
    { label: "Марафоны", path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH) },
    { label: "Ваучеры", path: createPaths(ADMIN_PANEL_PATH, VOUCHERS_PATH) },
    // {
    //   label: "Старт-пакеты",
    //   path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH),
    // },
    { label: "Роли", path: createPaths(ADMIN_PANEL_PATH, ROLES) },
  ];

  const adminNavItems = [
    {
      label: "Уведомления",
      path: createPaths(ADMIN_PANEL_PATH, NOTIFICATIONS_PATH, ALL_USERS_PATH),
    },
    {
      label: "Пользователи",
      path: createPaths(ADMIN_PANEL_PATH, USERS_PATH, ALL_USERS_PATH),
    },
    { label: "Марафоны", path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH) },
    { label: "Ваучеры", path: createPaths(ADMIN_PANEL_PATH, VOUCHERS_PATH) },
    // {
    //   label: "Старт-пакеты",
    //   path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH),
    // },
  ];

  const volunteerNavItems = [
    { label: "Марафоны", path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH) },
    // {label: 'Старт-пакеты', path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH)},
  ];

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      <ul>
        {userRole === "Owner" &&
          ownerNavItems.map((item, index) => {
            return (
              <li className={styles.headerListItem} key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? styles.headerActiveClassName : styles.headerLink
                  }
                >
                  {item.label}{" "}
                  {item.label === "Уведомления" &&
                    unreadNotificationsCount > 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          position: "absolute",
                          left: "-25px",
                          top: "-10px",
                          color: "#521A1A",
                          backgroundColor: "#FDCCCC",
                          padding: "5px",
                          borderRadius: "40px",
                          lineHeight: "12px",
                        }}
                      >
                        {unreadNotificationsCount}
                      </Typography>
                    )}
                </NavLink>
              </li>
            );
          })}
        {userRole === "Admin" &&
          adminNavItems.map((item, index) => {
            return (
              <li className={styles.headerListItem} key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? styles.headerActiveClassName : styles.headerLink
                  }
                >
                  {item.label}{" "}
                  {item.label === "Уведомления" &&
                    unreadNotificationsCount > 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          position: "absolute",
                          left: "-25px",
                          top: "-10px",
                          color: "#521A1A",
                          backgroundColor: "#FDCCCC",
                          padding: "5px",
                          borderRadius: "40px",
                          lineHeight: "12px",
                        }}
                      >
                        {unreadNotificationsCount}
                      </Typography>
                    )}
                </NavLink>
              </li>
            );
          })}
        {userRole === "Volunteer" &&
          volunteerNavItems.map((item, index) => {
            return (
              <li className={styles.headerListItem} key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? styles.headerActiveClassName : styles.headerLink
                  }
                >
                  {item.label}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </Box>
  );
};

export default AdminNavItems;
