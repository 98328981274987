import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const deleteFileCreateThunk = createAsyncThunk(
  "personalCabinet/deleteFile",
  async (queryParams, { rejectedWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.deleteFile(queryParams);
      return data;
    } catch (error) {
      return rejectedWithValue(error.response.data);
    }
  }
);
