import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';
import { toast } from "react-toastify";

export const deleteSubVoucher = createAsyncThunk(
  'user/deleteSubVoucher',
  async (subVoucherId: number, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.deleteSubVoucher(subVoucherId);
      toast.success('Субваучер успешно удален', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return response.data;
    } catch (error: any) {
      if (error.response?.data?.status === 404) {
        toast.error('Субваучер не найден', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Субваучер не найден');
      }
      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
    }
  }
);