import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { MoreIcon } from "assets/icons";
import { FC, useState } from "react";
import closeIcon from "assets/images/modal/x.svg";
import trashIcon from "assets/images/admin/trash_red.svg";
import { ConfirmModal } from "shared/ui/ConfirmModal";
import { deleteSubVoucher } from "entities/admin/model/thunk/deleteSubVoucher";
import { deactivateSubVoucher } from "entities/admin/model/thunk/deactivateSubVoucher";
import { useAppDispatch } from "shared/lib/hooks/hooks";
import { getSubVouchersCreateThunk } from "entities/viewer/model/thunks/getSubVouchersCreateThunk";

interface ISubVoucherEditModal {
  clickedSubVoucherId: number;
  name: string;
  isActive: number;
  corporateClientId: number;
  pageSubVoucherId: number;
}

export const SubVoucherEditModal: FC<ISubVoucherEditModal> = ({ pageSubVoucherId, clickedSubVoucherId, name, isActive: initialIsActive, corporateClientId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 6,
  });

  const handleMenuOpen = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const [isDeleteSubvaucherModalOpen, setIsDeleteSubvaucherModalOpen] =
    useState(false);
  const handleIsDeleteSubvaucherModalOpen = () =>
    setIsDeleteSubvaucherModalOpen(true);
  const handleIsDeleteSubvaucherModalClose = () =>
    setIsDeleteSubvaucherModalOpen(false);
  const handleDeleteSubvaucher = async () => {
    const response = await dispatch(deleteSubVoucher(clickedSubVoucherId));
    if (response.meta.requestStatus === "fulfilled") {
      dispatch(getSubVouchersCreateThunk({ voucherId: pageSubVoucherId, corporateClientId, pagination }))
      handleIsDeleteSubvaucherModalClose();
    }
  };

  const [isActive, setIsActive] = useState(initialIsActive);
  const [isDeactivateSubvaucherModalOpen, setIsDeactivateSubvaucherModalOpen] = useState(false);
  const handleIsDeactivateSubvaucherModalOpen = () =>
    setIsDeactivateSubvaucherModalOpen(true);
  const handleIsDeactivateSubvaucherModalClose = () =>
    setIsDeactivateSubvaucherModalOpen(false);
  const handleDeactivateSubvaucher = async () => {
    const deactivateData = {
      subVoucherId: clickedSubVoucherId,
      name,
      isActive: isActive ? false : true,
    };
    const response = await dispatch(deactivateSubVoucher(deactivateData));
    if (response.meta.requestStatus === "fulfilled") {
      dispatch(getSubVouchersCreateThunk({ voucherId: pageSubVoucherId, corporateClientId, pagination }))
      handleIsDeleteSubvaucherModalClose();
    }
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        sx={{
          borderRadius: "32px",
          "&:hover": {
            background: "#0090D1",
          },
        }}
      >
        <MoreIcon onClick={(e: any) => {
          e.preventDefault();
          handleMenuOpen(e);
        }} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
        style={{ borderRadius: "10px" }}
        slotProps={{
          paper: {
            style: {
              borderRadius: "13px",
            },
          },
        }}
      >
        <MenuItem
          onClick={handleIsDeactivateSubvaucherModalOpen}
          sx={{
            padding: "10px 8px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#3E444A",
            fontFamily: "Raleway",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            "&:hover": {
              background: "#F0F8FF",
              borderRadius: "13px",
              color: "#0090D1",
            },
          }}
        >
          <ListItemIcon>
            <img src={closeIcon} alt="Закрыть" />
          </ListItemIcon>
          <span style={{ marginRight: "auto" }}>{isActive ? "Деактивировать" : "Активировать"}</span>
        </MenuItem>
        <MenuItem
          onClick={handleIsDeleteSubvaucherModalOpen}
          sx={{
            borderTop: "1px solid #ECF2F7",
            padding: "10px 8px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#FF6960",
            fontFamily: "Raleway",
            fontSize: "14px",
            borderRadius: "7px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            "&:hover": {
              background: "#F0F8FF",
              borderRadius: "13px",
            },
          }}
        >
          <ListItemIcon>
            <img style={{ paddingLeft: "4px" }} src={trashIcon} alt="Удалить" />
          </ListItemIcon>
          <span style={{ marginRight: "auto" }}>Удалить субваучера</span>
        </MenuItem>
      </Menu>

      <ConfirmModal
        open={isDeleteSubvaucherModalOpen}
        title="Вы действительно хотите удалить субваучера?"
        description="Это действие нельзя отменить. Вы уверены, что хотите продолжить?"
        confirmText="Удалить"
        cancelText="Отмена"
        onConfirm={handleDeleteSubvaucher}
        onCancel={handleIsDeleteSubvaucherModalClose}
      />
      <ConfirmModal
        open={isDeactivateSubvaucherModalOpen}
        title={isActive ? "Вы действительно хотите деактивировать субваучера?" : "Вы действительно хотите активировать субваучера?"}
        description=""
        confirmText={isActive ? "Деактивировать" : "Активировать"}
        cancelText="Отмена"
        onConfirm={handleDeactivateSubvaucher}
        onCancel={handleIsDeactivateSubvaucherModalClose}
      />
    </div>
  );
};
