import { Backdrop, Button, Fade, Modal, Typography } from "@mui/material";
import { FC } from "react";

interface ConfirmModalProps {
  open: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  closeOnBackdropClick?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  closeOnBackdropClick = true,
}) => {

  const handleClose = () => {
    if (closeOnBackdropClick) {
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            padding: "20px",
            outline: "none",
          }}
        >
          <Typography
            style={{
              fontFamily: "Raleway",
              fontSize: "18px",
              fontWeight: 600,
              color: "#3E444A",
              margin: "0 0 10px",
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Raleway",
              fontSize: "14px",
              fontWeight: 300,
              color: "grey",
              margin: "0 0 20px",
            }}
          >
            {description}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              style={{
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
            <Button
              style={{
                fontFamily: "Raleway",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "24px",
                color: "#fff",
                background: "#0090D1",
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "none",
              }}
              onClick={onConfirm}
              variant="contained"
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
