import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";


interface SubVoucherParams {
  voucherId: number;
  corporateClientId?: number;
  pagination?: {
    pageSize: number;
    page: number;
  };
}

export const getSubVouchersCreateThunk = createAsyncThunk(
  'personalCabinet/getSubVouchersCreateThunk',
  async ({ voucherId, corporateClientId, pagination }: SubVoucherParams, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.getSubVouchers({ voucherId, corporateClientId, pagination });
      return data;
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        return rejectWithValue(error.response.data.title);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);